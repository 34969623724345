<template>
    <div>
        <div v-if="resultsDataJson.length == 0" id="no-results-text">
            No identified features. <br />Please click the "i" button below the
            'Basemap' button in the map, or the 'Identify' button at the top of
            this sidebar. <br />Then click on the map to identify geometries
            available in that area.
        </div>
        <div v-else v-for="(item, index) in resultsDataJson" :key="index">
            <div
                style="margin: 10px; border-radius: 5px;border: 1px solid black; margin-top: 5PX; "
                class="p-2"
                v-for="(geom, idx) in item.Geometry"
                :key="idx"
            >
                <div class="identifyTitle">{{ item.geomType }}</div>
                <div>
                    <b-row
                        v-for="(subItem, subIndex) in geom.properties"
                        :key="subIndex"
                        no-gutters
                    >
                        <b-col cols="6" class="identifyLabel">{{
                            subItem.label
                        }}</b-col>
                        <b-col cols="6" class="property-value">
                            <a
                                v-if="
                                    subItem.label.toLowerCase() === 'file_link'
                                "
                                target="_blank"
                                :href="subItem.value"
                            >
                                {{
                                    subItem.value
                                        .split('/')
                                        .reverse()[0]
                                        .replace(/%20/g, ' ')
                                }}
                            </a>
                            <span v-else>{{ subItem.value }}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <span
                                v-for="(fileItem, fileIndex) in geom.documents"
                                :key="fileIndex"
                            >
                                <a
                                    v-if="!fileItem.src"
                                    class="fileName"
                                    @click="downloadFile(fileItem)"
                                    >{{ fileItem.FileName }}</a
                                >
                                <img
                                    v-if="fileItem.src"
                                    :src="fileItem.src"
                                    style="width: 300px;"
                                />
                                <br />
                            </span>
                        </b-col>
                    </b-row>
                    <b-row class="rowBtn">
                        <b-col>
                            <button
                                class="btn btn-identify btn-zoom"
                                v-show="item.zoom"
                                @click="zoomToIdentifyResult(geom)"
                            >
                                Zoom To
                            </button>
                            <button
                                class="btn btn-reverselookup btn-zoom"
                                v-show="item.canReverse"
                                @click="
                                    reverseLookup(
                                        item.layerID,
                                        parseInt(geom.properties[0].value)
                                    )
                                "
                            >
                                Receptor Lookup
                            </button>
                            <button
                                class="btn btn-identify"
                                v-show="item.editable"
                                @click="editIdentifyResult(geom)"
                            >
                                Edit
                            </button>
                            <button
                                class="btn btn-identify"
                                v-show="item.HCALookup"
                                @click="runHCAQuery(geom)"
                            >
                                HCA Lookup
                            </button>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { commonService } from '../../services/common.service';
import { spatialService } from '../../services/spatial.services.js';
import { spatialFunctions } from '../../utilities/spatialFunctions';

export default {
    name: 'MapIdentifyResults',
    props: {
        isEditing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            i: 0,
            // isActive: true
        };
    },
    methods: {
        ...mapActions('emergencyManagement', ['setDrawingAttributes']),
        ...mapMutations('emergencyManagement', {
            setShowAttributeModal: 'updateShowAttributeModal',
            loadDrawingAttributes: 'loadDrawingAttributes',
        }),
        ...mapActions('oneMap', ['setZoomToGeom']),
        ...mapMutations('oneMap', ['mutateDrawingProjectID']),
        zoomToIdentifyResult(feature) {
            let featureId = 0;
            let mapLayerId = 0;
            let coords = [0, 0];
            feature.properties.forEach((prop) => {
                if (prop.label == 'MapLayerID') {
                    mapLayerId = prop.value;
                } else if (prop.label == 'FeatureID') {
                    featureId = prop.value;
                } else if (
                    ['lat', 'latitude'].includes(prop.label.toLowerCase())
                ) {
                    coords[0] = parseFloat(prop.value);
                } else if (
                    ['lng', 'long', 'longitude'].includes(
                        prop.label.toLowerCase()
                    )
                ) {
                    coords[1] = parseFloat(prop.value);
                }
            });
            if (feature.geometry) {
                this.setZoomToGeom(feature);
            } else if (featureId != 0 && mapLayerId != 0) {
                spatialService
                    .featureGeometry(mapLayerId, featureId)
                    .then((res) => {
                        feature.geometry = res.data.geometry;
                        this.setZoomToGeom(feature);
                    });
            } else if (coords != [0, 0]) {
                this.setZoomToGeom({
                    geometry: {
                        coordSys: 'LatLong',
                        coordinates: coords,
                        type: 'Point',
                    },
                });
            }
        },
        editIdentifyResult(feature) {
            let attributes = {};
            let featureId = 0;
            let mapLayerId = 0;
            feature.properties.forEach((prop) => {
                if (prop.label == 'MapLayerID') {
                    mapLayerId = prop.value;
                } else if (prop.label == 'FeatureID') {
                    featureId = prop.value;
                }
                switch (prop.label) {
                    case 'LayerDescription':
                        break;
                    case 'FeatureID':
                        attributes['geoID'] = parseInt(prop.value);
                        break;
                    case 'LocName':
                        attributes['geomLocName'] = prop.value;
                        break;
                    case 'LocType':
                        attributes['geomLocType'] = prop.value;
                        break;
                    case 'GeoDescription':
                        attributes['geomLocDesc'] = prop.value;
                        break;
                    case 'Comment':
                        attributes['geomComment'] = prop.value;
                        break;
                    case 'ProjectID':
                        attributes['projectID'] = parseInt(prop.value);
                        this.mutateDrawingProjectID(parseInt(prop.value));
                        break;
                }
            });
            attributes.geomLocIcon = '';
            if (!Object.keys(feature).includes('geometry')) {
                spatialService
                    .featureGeometry(mapLayerId, featureId)
                    .then((res) => {
                        feature.geometry = res.data.geometry;
                        attributes.geomWKT = spatialFunctions.convertToWKT(
                            feature,
                            feature.geometry.coordSys == 'LatLong'
                        );
                        attributes.geometry = feature.geometry;
                        this.loadDrawingAttributes(attributes);
                        this.setShowAttributeModal(true);
                    });
            } else {
                attributes.geomWKT = spatialFunctions.convertToWKT(
                    feature,
                    feature.geometry.coordSys == 'LatLong'
                );
                attributes.geometry = feature.geometry;
                this.loadDrawingAttributes(attributes);
                this.setShowAttributeModal(true);
            }
        },
        reverseLookup(layerID, featureID) {
            this.$emit('reverseLookup', layerID, featureID);
        },
        runHCAQuery(feature) {
            this.$emit('runHCA', feature, 'identify');
        },
        incrementResultCount() {
            this.i++;
        },
        downloadFile(file) {
            //
            commonService.downloadThumbnailByID(file.DocumentId, file.FileName);
        },
    },
    computed: {
        ...mapState('oneMap', ['identifyResultsData']),
        resultsDataJson() {
            let resultJson = [];
            try {
                this.identifyResultsData.forEach((layer) => {
                    if (layer && layer.Geometry.length > 0) {
                        resultJson.push(layer);
                    }
                });
            } catch (e) {
                return [];
            }
            return resultJson;
        },
    },
};
</script>

<style scoped>
.btn {
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 40px;
    /* need correct font-family */
}
.btn-identify {
    background-color: rgb(129, 129, 129);
    color: white;
    width: 90px;
    height: 40px;
}
.btn-reverselookup {
    background-color: rgb(129, 129, 129);
    color: white;
    width: 120px;
    text-wrap: wrap;
    height: 60px;
}
.btn-zoom {
    margin-right: 10px;
}
.identifyTitle {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 5px;
    font-family: 'IBMPlexSans-Regular';
}
.card {
    border: solid 1px gray !important;
    margin-bottom: 10px;
}
.card:hover {
    border: solid 2px rgb(77, 194, 233) !important;
    transition: 0.1s;
}
.rowBtn {
    padding-top: 10px;
}
.fileName {
    color: #006395 !important;
    text-decoration: underline !important;
    font-weight: bold;
}

#no-results-text {
    padding: 10px 10px 0px 10px;
}

.identifyLabel {
    font-weight: bold;
    font-size: 13px;
    font-family: 'IBMPlexSans-Regular';
}

.property-value {
    display: flex;
    align-items: center;
    font-size: 14px;
    word-break: break-all;
}

.property-value a {
    text-decoration: none;
    color: #007bff;
}
</style>
