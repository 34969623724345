<template>
    <div>
        <div id="buttons">
            <button
                @click="startIdentify"
                class="btn"
                v-bind:class="
                    activeMapTool == 'identify' ? 'btn-blue' : 'btn-gray'
                "
            >
                Identify
            </button>
            <!-- only show the 'Clear results' if we have features to clear -->
            <button
                v-show="identifiedFeaturesCount > 0"
                @click="clearResults"
                class="btn btn-gray"
            >
                Clear results
            </button>
        </div>
        <div id="identifyResults">
            <map-identify-results></map-identify-results>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import MapIdentifyResults from '../../CommonComponents/MapIdentifyResults.vue';

export default {
    name: 'IdentifySidebar',
    props: {},
    components: { MapIdentifyResults },
    data() {
        return {};
    },
    methods: {
        ...mapActions('oneMap', {
            setMapTool: 'setMapTool',
            clearMapTool: 'clearMapTool',
            clearHighlight: 'clearHighlightGeom',
            clearIdBuffer: 'clearIdBuffer',
        }),
        ...mapMutations('oneMap', {
            mutateIdentifyResultsData: 'mutateIdentifyResultsData',
        }),
        startIdentify() {
            if (this.activeMapTool == 'identify') {
                this.clearMapTool();
            } else {
                this.setMapTool('identify');
                this.$store.commit('oneMap/mutateCloseSidebars', true);
            }
            // have to make the map change quickly to toggle the geoJson layer popups
            this.$parent.$parent.$refs.map.zoom++;
            this.$nextTick();
            this.$parent.$parent.$refs.map.zoom--;
        },
        clearResults() {
            this.clearHighlight();
            this.clearIdBuffer();
            this.mutateIdentifyResultsData([]);
            this.$store.dispatch('oneMap/clearIdentifyPayload');

            // have to make the map change quickly to toggle the geoJson layer popups
            this.$parent.$parent.$refs.map.zoom++;
            this.$nextTick();
            this.$parent.$parent.$refs.map.zoom--;
        },
    },
    computed: {
        ...mapState('oneMap', ['activeMapTool', 'identifyResultsData']),
        ...mapGetters('oneMap', {
            highlightFeatureCount: 'highlightFeatureCount',
        }),
        ...mapGetters('projects', ['selectedProjects']),

        // get the count of identified records, the way the identifyResultsData structure
        //  is set up, even if there are no features, we have feature groups for each of
        //  the identified layers
        identifiedFeaturesCount() {
            let featureCount = 0;
            if (this.identifyResultsData && this.identifyResultsData.length) {
                this.identifyResultsData.forEach((res) => {
                    featureCount += res.Geometry.length;
                });
            }
            return featureCount;
        },
    },
    watch: {
        selectedProjects(newProjects, oldProjects) {
            if (newProjects != oldProjects) {
                this.clearResults();
                if (this.activeMapTool == 'identify') {
                    this.clearMapTool();
                }
            }
        },
    },
};
</script>

<style scoped>
.btn-gray {
    background-color: gray !important;
    color: white;
    width: fit-content;
    /* height: 42px; */
}
.btn-gray:focus {
    background-color: rgb(77, 194, 233) !important;
}
.btn-gray:active {
    background-color: rgb(77, 194, 233) !important;
}
.btn-gray:hover {
    background-color: darkgrey;
}
.btn-blue {
    background-color: rgb(77, 194, 233) !important;
}
#buttons {
    padding: 10px 10px 0px 10px;
    display: flex;
    justify-content: space-between;
}
</style>
